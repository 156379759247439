import { EuiSkeletonCircle, EuiSkeletonLoading } from "@equipmentshare/ds2";
import {
  type DataVizContainerDimensionsDefaults,
  type PieDatum,
  type SliceDatum,
} from "@fleet-configuration/components";

import { PieChart } from "@/components/fleet-overview/pie-chart-with-legend/pie-chart";
import { lightGrey } from "@/components/fleet-overview/pie-chart-with-legend/utils";

export type PieChartWithSkeletonProps = {
  containerDimensions?: DataVizContainerDimensionsDefaults;
  data: SliceDatum[];
  isLoading: boolean;
};

export const PieChartWithSkeleton = (props: PieChartWithSkeletonProps) => {
  const noData = !props.isLoading && props.data.length === 0;
  const dataTestId = props.isLoading
    ? "pie-chart-with-skeleton"
    : noData
      ? "empty-pie-chart"
      : "pie-chart-with-data";

  const renderChart = () => {
    const emptyPieData = [
      {
        id: "empty",
        label: "",
        value: 100,
        color: lightGrey,
      },
    ];

    const chartData: PieDatum[] = props.data.map((item) => ({
      id: item.name,
      label: item.name,
      value: item.value,
      color: item.color,
    }));

    return (
      <PieChart
        containerDimensions={props.containerDimensions}
        data={noData ? emptyPieData : chartData}
        showArcLabels={noData ? false : true}
      />
    );
  };

  return (
    <EuiSkeletonLoading
      contentAriaLabel="pie chart"
      data-testid={dataTestId}
      isLoading={props.isLoading}
      loadedContent={renderChart()}
      loadingContent={
        <EuiSkeletonCircle
          data-testid="pie-chart-skeleton-circle"
          isLoading={props.isLoading}
          style={{
            margin: "16px",
            height: props.containerDimensions?.containerHeight ?? "100%",
            width: props.containerDimensions?.containerWidth ?? "100%",
          }}
        />
      }
    />
  );
};
