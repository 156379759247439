import { EuiFlexGroup, EuiFlexItem } from "@equipmentshare/ds2";
import {
  type DataVizContainerDimensionsDefaults,
  type SliceDatum,
} from "@fleet-configuration/components";

import { PieChartLegend } from "@/components/fleet-overview/pie-chart-with-legend/pie-chart-legend";
import { PieChartWithSkeleton } from "@/components/fleet-overview/pie-chart-with-legend/pie-chart-with-skeleton";

export type PieChartWithLegendStyles = {
  mainContainer?: {
    minHeight: string;
  };
};

export type PieChartWithLegendProps = {
  containerDimensions?: DataVizContainerDimensionsDefaults;
  data: SliceDatum[];
  isLoading: boolean;
};

export const PieChartWithLegend = (props: PieChartWithLegendProps) => {
  return (
    <EuiFlexGroup
      alignItems="center"
      data-testid="pie-chart-with-legend"
      gutterSize="m"
      responsive={false}
    >
      <EuiFlexItem grow={1}>
        <PieChartWithSkeleton
          containerDimensions={props.containerDimensions}
          data={props.data}
          isLoading={props.isLoading}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={1}>
        <PieChartLegend data={props.data} isLoading={props.isLoading} />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
