import {
  EuiBadge,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSkeletonText,
  EuiText,
  useIsWithinBreakpoints,
} from "@equipmentshare/ds2";
import { type SliceDatum } from "@fleet-configuration/components";

import {
  pieChartColor,
  pieChartLabel,
} from "@/components/fleet-overview/pie-chart-with-legend/utils";
import { ASSET_TYPE_ID } from "@/types";

export const defaultDataTypes: SliceDatum[] = Object.values(ASSET_TYPE_ID)
  .filter((v) => typeof v === "number")
  .map((id) => ({
    name: pieChartLabel(id as ASSET_TYPE_ID),
    value: 0,
    color: pieChartColor(id as ASSET_TYPE_ID),
  }));

export type PieChartLegendProps = {
  data: SliceDatum[];
  isLoading: boolean;
};

export const PieChartLegend = (props: PieChartLegendProps) => {
  const emptyData = !props.isLoading && !props.data.length;
  const legendData = emptyData ? defaultDataTypes : props.data;
  const dataTestId = emptyData ? "default-legend" : "pie-chart-legend";

  const justifyFlexStart = useIsWithinBreakpoints(["s", "m"]);
  const columnCount = useIsWithinBreakpoints(["s", "m", "xl"]) ? 2 : 1;

  return props.isLoading ? (
    <EuiSkeletonText data-testid="legend-skeleton" lines={3} />
  ) : (
    <EuiFlexGroup
      alignItems="center"
      data-testid={dataTestId}
      gutterSize="s"
      justifyContent={justifyFlexStart ? "flexStart" : "center"}
      responsive={false}
      wrap
    >
      <EuiFlexGrid columns={columnCount} gutterSize="s">
        {legendData?.map((d, i) => (
          <EuiFlexItem key={d.name}>
            <EuiFlexGroup alignItems="center" gutterSize="s" responsive={false}>
              <EuiFlexItem grow={false}>
                <EuiBadge color={d.color} data-testid={`badge-${d.name}`} />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText size="xs">{d.name}</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        ))}
      </EuiFlexGrid>
    </EuiFlexGroup>
  );
};
